<template>
    <div class="inform_table_wrap">
        <div class="table_wrap">
            <div class="table_top">
                <div class="table_top_div">{{ $t('edition') }}</div>
                <div class="table_top_div">{{ $t('type') }}</div>
                <div class="table_top_div">{{ $t('price2') }}</div>
                <div class="table_top_div">{{ $t('owner') }}</div>
                <div class="table_top_div">{{ $t('select') }}</div>
            </div>
            <div class="table_content">
                <div class="table_list" v-for="(data,index) in itemData" :key="`edition${index}`">
                    <div class="table_list_div">{{ data.im_count }} of {{ data.im_total }}</div>
                    <div class="table_list_div">{{ returnItemType(data) }}</div>
                    <div class="table_list_div list_position">
                        <div class="list_position_top">
                            <img src="@/assets/image/main/mingle_small_logo.png">
                            <span>{{ data.i_price }} MG</span>
                        </div>
                        <br/>
                        <span class="list_position_bottom">({{ returnMingle(data.i_price) }})</span>
                    </div>
                    <div class="table_list_div">{{ data.provider_nick }}</div>
                    <div class="table_list_div" @click="moveEdition(data.i_sku)">
                        <button class="div_select">{{ $t('select') }}</button>
                    </div>
                </div>
                <lazyload-loading-layout :pagination-data='paginationData'></lazyload-loading-layout>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import priceMixins from "@/mixins/priceMixins";
import LazyloadLoadingLayout from "@/template/item/pagination/LazyloadLoadingLayout";
import EventBus from "@/utils/event-bus";

let getItemInterval = null;
export default {
    name: "ItemListEditionItemLayout",
    mixins: [priceMixins],
    components: {LazyloadLoadingLayout},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {},
        numberSort: 'asc',
        typeSort: 'asc',
        priceSort: 'asc',

        isGetData: false,
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        getItemInterval = setInterval(() => {
            EventBus.$emit('getItemList')
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnItemType(data) {
            if (util.isEmpty(data)) {
                return
            }
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    return diffTime;
                } else {
                    return this.$t('beforeAuction');
                }
            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                return this.$t('no_sell')
            }
        },

        moveEdition(data) {
            let type = this.$route.name
            console.log(type)
            if (type === 'PremiumMarketDetail') {
                this.$router.replace(`/premiummarket/${data}?tab=item`);
                this.$router.go();
            } else if (type === 'OpenMarketDetail') {
                this.$router.replace(`/openmarket/${data}?tab=item`);
                this.$router.go();
            } else {
                this.$router.replace(`/item/${data}?tab=item`);
                this.$router.go();
            }
        },
        sortEdition(type) {
            console.log(type)
            EventBus.$emit('editionSort', type, 0)
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
